import React from 'react';
import ainLogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="ain__footer section__padding">
    <div className="ain__footer-heading">
      <h1 className="gradient__text">Let your ideas become reality</h1>
    </div>

    <div className="ain__footer-links">
      <div className="ain__footer-links_logo">
        <img src={ainLogo} alt="ain_logo" />
      </div>
      <div className="ain__footer-links_div">
        <h4>Links</h4>
        <p><a href="https://twitter.com/ALL_IN_NFT_">Twitter</a></p>
        <p><a href="https://www.instagram.com/allin_nft_zookeeper/">Instagram</a></p>
        <p><a href="https://t.me/all_in_nfts_official/">Telegram</a></p>
        <p><a href="mailto: info@all-in-nfts.com">Mail</a></p>
        <p><a href="https://discord.gg/23BukUsZww">Discord</a></p>
      </div>
      <div className="ain__footer-links_div">
        <h4>Get in touch</h4>
        <p>info@all-in-nfts.com</p>
      </div>
    </div>

    <div className="ain__footer-copyright">
      <p>@2022 AINFT. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
