import React from 'react';
import ReactPlayer from 'react-player';
import Feature from '../../components/feature/Feature';
import './possibility.css';

function Possibility() {
  return (
    <div>
      <ReactPlayer
        url="https://youtu.be/mOqDUOOhjYY"
      />
      <div className="ain__whatain-container">
        <Feature title="Dungeon Loot" text="A mysterious merchant came back from a long, epic journey. He collected loot from the darkest dungeons all over the realm for adventurers like you to purchase. Buy these exclusive items and take them on your own epic journey. Every piece of this collection is unique, you will receive a 2D render accompanied by a 3D Metaverse and gaming ready 3D file." />
      </div>
    </div>
  );
}

export default Possibility;
