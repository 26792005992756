import React from 'react';
import ai from '../../assets/all-in-nft.png';
import './header.css';

const Header = () => (
  <div className="ain__header section__padding" id="home">
    <div className="ain__header-content">
      <h1 className="gradient__text">Where an idea becomes reality</h1>
      <p>An idea often never transforms from a figment of imagination into practice, not because the idea is bad, but because of the lack of knowledge inhibits it coming into full fruition. We aim to bring together people with different skillsets to collaborate on these ideas and make them happen. </p>
    </div>

    <div className="ain__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
