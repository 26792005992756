import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="ain__blog section__padding" id="blog">
    <div className="ain__blog-heading">
      <h1 className="gradient__text">Featured projects </h1>
    </div>
    <div className="ain__blog-container">
      <div className="ain__blog-container_groupA">
        <div>
          <a href="https://all-in-nft.medium.com/new-collection-grillaz-af61edcdf76d">
            <Article imgUrl={blog05} date="May 26, 2022" text="Introducing: Grillaz" />
          </a>
        </div>
      </div>
      <div className="ain__blog-container_groupB">
        <div>
          <a href="https://medium.com/all-in-nfts/new-collection-mudskippers-a81b0d54b5cf">
            <Article imgUrl={blog02} date="Jan 27, 2022" text="Introducing: MudSkippers"> </Article>
          </a>
        </div>
        <div>
          <a href="https://medium.com/all-in-nfts/dungeon-loot-001-009-34527561d5e5">
            <Article imgUrl={blog01} date="Jan 10, 2022" text="Dungeon Loot 001–009" />
          </a>
        </div>
        <Article imgUrl={blog03} text="Papi Chulo NFTS - More info soon" />
        <Article imgUrl={blog04} text="Microbials- the unkillable 0.1% - More info soon" />
      </div>
    </div>
  </div>
);

export default Blog;
