import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatain.css';

const Whatain = () => (
  <div className="ain__whatain section__margin" id="all-in nft">
    <div className="ain__whatain-feature">
      <Feature title="What is All-IN NFTs" text="ALL–IN NFTS is a collective of artists, creators, designers and developers who are experienced in delivering results in their own specialty. To give it all, is the best way to describe our work-ethic. We want to build a platform where we make things happen. A place which is created for everyone, where we help you facilitate your NFT artworks and innovations." />
    </div>
    <div className="ain__whatain-heading">
      <h1 className="gradient__text">The possibilities are limitless</h1>
    </div>
    <div className="ain__whatain-container">
      <Feature title="Metaverse" text="We have 3D designers that are able to create any item that has usage in a metaverse" />
      <Feature title="Art" text="We can provide artists that can design your idea. We can provide developers that can write code for artists that lack coding skills." />
      <Feature title="Ticketing" text="Need a ticketing service for your event? We can facilitate this for you. Our service  prevents scalping." />
    </div>
  </div>
);

export default Whatain;
