import React from 'react';
import { openzoo, wanchain, papichulo, melnornia } from './imports';
import './brand.css';

const Brand = () => (
  <div className="ain__brand section__padding">
    <div>
      <a href="https://openzoo.io">
        <img src={openzoo} />
      </a>
    </div>
    <div>
      <a href="https://www.wanchain.org/">
        <img src={wanchain} />
      </a>
    </div>
    <div>
      <a href="https://papichulo.nl/">
        <img src={papichulo} />
      </a>
    </div>
    <div>
      <a href="https://melnoria.com/">
        <img src={melnornia} />
      </a>
    </div>
  </div>
);

export default Brand;
